export const FAUCET_API =
  "https://api.defender.openzeppelin.com/autotasks/949df118-288e-4e3b-9d7b-c6802d9a517c/runs/webhook/30f62948-f82f-41b9-96aa-4cad6bbbab8f/3UVum1nFFmXB2XjZ6THARc";

export const METAMASK_ASSETS = [
  {
    name: "DAI Token",
    symbol: "DAI",
    decimals: 18,
    address: "0x76a245568c71c221a2ce4a300359333ddd2eca2c",
  },
  {
    name: "Tether USD",
    symbol: "USDT",
    decimals: 6,
    address: "0xCc2a3D6a57824469C3708baB4eb5d62cb1c460dE",
  },
  {
    name: "USD Coin",
    symbol: "USDC",
    decimals: 6,
    address: "0x943f53c8A4C5f3Ea20C0CacFde6027De46890aD6",
  },
  {
    name: "INFI",
    symbol: "INFI",
    decimals: 18,
    address: "0x082A7daac615Fbbfd0CF39FB3e366255723C7fd5",
  },
];
