import { Container, Grid, Grow } from "@mui/material";
import React, { useState } from "react";
import "./Roadmap.scss";

const Roadmap = () => {
  const roadmapData = [
    {
      isComingSoon: false,
      year: 2020,
      content: {
        q4: ["Equity Funding, Going Public, Token-Private Sale"],
      },
    },
    {
      isComingSoon: false,
      year: 2021,
      content: {
        q1: [
          "PR and Community Building",
          "Token Launch and Listing on Exchanges",
          "Research and Gathering Requirements",
        ],
        q2: ["Partnership with Polygon (Matic Network)", "Product Development"],
        q3: ["Product Development and Internal Release & Testing"],
        q4: [
          "Soft launch on Mumbai Testnet",
          "Establish Product Metrics and Monitor Dashboard",
        ],
      },
    },
    {
      isComingSoon: true,
      year: 2022,
      content: {
        q4: ["Equity Funding, Going Public, Token-Private Sale"],
      },
    },
  ];

  const [roadmap, setRoadmap] = useState(roadmapData[1]);

  console.log("roadmap", roadmap);

  return (
    <div className="roadmap">
      <Container fixed>
        <Grid container>
          <Grid item xs={12}>
            <div className="roadmap__description" data-aos="zoom-in">
              <h1>Roadmap 🚀</h1>
              <div className="roadmap__option">
                <div
                  className={`roadmap__item ${
                    roadmap.year === 2020 && "active-roadmap"
                  }`}
                  onClick={() => setRoadmap(roadmapData[0])}
                >
                  2020
                </div>
                <div
                  className={`roadmap__item ${
                    roadmap.year === 2021 && "active-roadmap"
                  }`}
                  onClick={() => setRoadmap(roadmapData[1])}
                >
                  2021
                </div>
                <div
                  className={`roadmap__item ${
                    roadmap.year === 2022 && "active-roadmap"
                  }`}
                  onClick={() => setRoadmap(roadmapData[2])}
                >
                  2022
                </div>
              </div>
              <div className="roadmap__content">
                {roadmap.isComingSoon ? (
                  <h3>COMING SOON</h3>
                ) : (
                  Object.keys(roadmap.content).map((data) => (
                    <div className="roadmap__content-item">
                      <h3>{data.toUpperCase()}</h3>
                      <ul>
                        {roadmap.content[data].map((value) => (
                          <li>{value}</li>
                        ))}
                      </ul>
                    </div>
                  ))
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Roadmap;
