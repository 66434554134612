import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import bloomberg from "../../images/featured/bloomberg.png";
import yahooFinance from "../../images/featured/yahoo-finance.png";
import yahooMoney from "../../images/featured/yahoo-money.png";
import yahooNews from "../../images/featured/yahoo-news.png";
import "./Featured.scss";

const Featured = () => {
  return (
    <Container fixed>
      <div className="featured" data-aos="fade-up" data-aos-duration="1000">
        <Grid container>
          <Grid item xs={12}>
            <div className="featured__description">
              <div className="featured__item_container">
                <Grid container spacing={{ xs: 2, sm: 2, lg: 2 }}>
                  <Grid item lg={3} md={6} xs={6}>
                    <Box className="featured__items">
                      <img
                        src={bloomberg}
                        alt="Bloomberg"
                        width="152px"
                        height="28px"
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={6} xs={6}>
                    <Box className="featured__items">
                      <img
                        src={yahooFinance}
                        alt="Yahoo Finance"
                        width="140px"
                        height="60px"
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={6} xs={6}>
                    <Box className="featured__items">
                      <img
                        src={yahooNews}
                        alt="Yahoo News"
                        width="137px"
                        height="48px"
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={6} xs={6}>
                    <Box className="featured__items">
                      <img
                        src={yahooMoney}
                        alt="Yahoo Money"
                        width="194px"
                        height="28px"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default Featured;
