import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import nabais from "../../images/investor/nabais.png";
import vysyn from "../../images/investor/vysyn.png";
import "./Investors.scss";

const Investors = () => {
  return (
    <div className="investors">
      <Container fixed>
        <Grid container>
          <Grid item xs={12}>
            <div className="investors__description">
              <h1 data-aos="fade-up">Trusted by Investors</h1>
              <Grid container spacing={0} data-aos="fade-down">
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <img src={nabais} alt="Nabais" width={200} height={52} />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <img src={vysyn} alt="VYSYN" width={188} height={16} />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/vendetta.png"
                      alt="Vendetta"
                      width={110}
                      height={48}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/amplifi.png"
                      alt="Amplifi"
                      width={60}
                      height={50}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/hronos.png"
                      alt="Hronos"
                      width={111}
                      height={40}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/x21.png"
                      alt="X21"
                      width={74}
                      height={53}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/morningstar.png"
                      alt="Morning Star"
                      width={148}
                      height={52}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/signal.png"
                      alt="Signal"
                      width={208}
                      height={40}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/cspdao.png"
                      alt="cspdao"
                      width={196}
                      height={44}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/bluenode.png"
                      alt="Bluenode"
                      width={146}
                      height={48}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/moonrock.png"
                      alt="Moonrock"
                      width={190}
                      height={52}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/sky.png"
                      alt="Sky"
                      width={111}
                      height={60}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/delta.png"
                      alt="Delta"
                      width={70}
                      height={60}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/fairum.png"
                      alt="Fairum"
                      width={188}
                      height={32}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4} className="investor-grid">
                  <Box className="investors__items">
                    <StaticImage
                      src="../../images/investor/gains.png"
                      alt="Gains"
                      width={113}
                      height={52}
                    />
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Investors;
