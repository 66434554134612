import { BigNumber } from "@ethersproject/bignumber";
import { Close } from "@mui/icons-material";
import { Button, Container, Grid, Grow } from "@mui/material";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import React, { useState } from "react";
import ReactGA from "react-ga";
import Lottie from "react-lottie";
import { FAUCET_API } from "../../constants";
import * as failedAnimation from "../../images/failed.json";
import happyPeople from "../../images/happy-people.png";
import * as transferAnimation from "../../images/loading.json";
import shieldPerspective from "../../images/shield_perspective.svg";
import * as successAnimation from "../../images/success.json";
import "./Jargon.scss";
import { ethers } from "ethers";

const Jargon = () => {
  const [address, setAddress] = useState("");
  const [inputModal, setInputModal] = useState(false);
  const [modalState, setModalState] = useState({
    status: false,
    message: "",
    title: "",
    txId: null,
  });

  const requestTokens = async () => {
    mixpanel.track("Faucet");
    ReactGA.event({
      category: "Faucet",
      action: "RequestToken",
      label: "RequestToken",
      value: address,
    });

    setInputModal(false);
    setModalState({
      status: true,
      message:
        "Your transfer is in progress. It may take upto a few seconds to complete the transfer.",
      title: "Waiting",
      txId: null,
    });

    axios
      .post(FAUCET_API, {
        address: address,
      })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.status === "success") {
            let result = JSON.parse(response.data.result);
            if (result.tx === null) {
              ReactGA.event({
                category: "Faucet",
                action: "TransferTokenUnsuccessful",
                label: "TransferTokenUnsuccessful",
                value: address,
              });
              setAddress("");
              const nextTime =
                BigNumber.from(result.nextClaimTime).toNumber() * 1000;
              const nextTimeRequest = new Date(nextTime).toLocaleString();
              setModalState({
                status: true,
                message: `You will be able to make request in ${nextTimeRequest}`,
                title: "Transfer Unsuccessful",
                txId: null,
              });
            } else {
              ReactGA.event({
                category: "Faucet",
                action: "TransferTokenSuccessful",
                label: "TransferTokenSuccessful",
                value: address,
              });
              setAddress("");
              setModalState({
                status: true,
                message: "The transfer has been made to your wallet address",
                title: "Transfer Successful",
                txId: result.tx,
              });
            }
          } else {
            ReactGA.event({
              category: "Faucet",
              action: "TransferTokenError",
              label: "TransferTokenError",
              value: address,
            });
            setAddress("");
            setModalState({
              status: true,
              message: "Oops... Something went wrong",
              title: "Transfer Error",
              txId: null,
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    setAddress(e.target.value);
  };

  const handleClose = (e) => {
    setModalState({ status: false, message: "", title: "", txId: null });
  };

  const addTokens = async () => {
    //check metamask is installed
    if (window.ethereum === undefined)
      return window.alert("Please install Metamask!");

    console.log("metamask", window.ethereum.isMetaMask);
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    await provider.send("eth_requestAccounts", []);

    //check metamask is connected!
    //console.log("connected eth", window.ethereum.isConnected());

    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x5" }],
      });
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: "0x76a245568c71c221a2ce4a300359333ddd2eca2c", // The address that the token is at.
            symbol: "DAI", // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image:
              "https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png", // A string url of the token logo
          },
        },
      });
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: "0xCc2a3D6a57824469C3708baB4eb5d62cb1c460dE", // The address that the token is at.
            symbol: "USDT", // A ticker symbol or shorthand, up to 5 chars.
            decimals: 6, // The number of decimals in the token
            image: "https://cryptologos.cc/logos/tether-usdt-logo.png?v=002", // A string url of the token logo
          },
        },
      });
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: "0x943f53c8A4C5f3Ea20C0CacFde6027De46890aD6", // The address that the token is at.
            symbol: "USDC", // A ticker symbol or shorthand, up to 5 chars.
            decimals: 6, // The number of decimals in the token
            image: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=002", // A string url of the token logo
          },
        },
      });
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: "0x082A7daac615Fbbfd0CF39FB3e366255723C7fd5", // The address that the token is at.
            symbol: "INFI", // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image:
              "https://s2.coinmarketcap.com/static/img/coins/64x64/8305.png", // A string url of the token logo
          },
        },
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x5",
                chainName: "Goerli Test Network",
                rpcUrls: ["https://rpc.goerli.mudit.blog/"] /* ... */,
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 434,
    height: 492,
    background: "#FFFFFF",
  };

  const transferAnimationData = {
    loop: true,
    autoplay: true,
    animationData: transferAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const successAnimationData = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const failedAnimationData = {
    loop: true,
    autoplay: true,
    animationData: failedAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  console.log(modalState);

  return (
    <div className="jargon">
      <Container fixed>
        {inputModal && (
          <Grow in={inputModal}>
            <div className="jargon__modal">
              <div className="close_button">
                <h1>Testnet Tokens Faucet</h1>
                <Close onClick={() => setInputModal(false)} />
              </div>
              <img src={shieldPerspective} alt="Token " />
              <div className="input__field">
                <span className="wallet">Wallet Address</span>
                <input
                  type="text"
                  value={address}
                  placeholder="Enter address to receive funds here"
                  onChange={handleChange}
                />
                <span>
                  You will receive INFI, USDT, USDC, and DAI tokens on Goerli
                </span>
                <div className="button-wrapper">
                  <Button
                    variant="contained"
                    className="button-faucet"
                    onClick={requestTokens}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Grow>
        )}
        {modalState.status && (
          <div className="jargon__modal_wrapper">
            {modalState.txId === null && modalState.title === "Waiting" ? (
              <div className="jargon__modal">
                <Lottie
                  options={transferAnimationData}
                  height={280}
                  width={280}
                />
                <h3>Transfer in Progress</h3>
                <p>
                  Your transfer is in progress. It may take up to a few <br />
                  seconds to complete the transfer.
                </p>
              </div>
            ) : (
              <div className="jargon__modal">
                <div className="close_button">
                  <h1>Testnet Tokens Faucet</h1>
                  <Close onClick={handleClose} />
                </div>
                {modalState.title === "Transfer Successful" && (
                  <div>
                    <Lottie
                      options={successAnimationData}
                      height={280}
                      width={280}
                    />
                    <h3>Transfer Successful</h3>
                    <p>The transfer has been made to your wallet address.</p>
                    <div className="button-wrapper">
                      <Button
                        variant="contained"
                        className="outlined-button-faucet"
                        href={`https://goerli.etherscan.io/tx/${modalState.txId}`}
                      >
                        View on Etherscan
                      </Button>
                      <Button
                        variant="contained"
                        className="button-faucet"
                        onClick={addTokens}
                      >
                        Add tokens to metamask
                      </Button>
                    </div>
                  </div>
                )}
                {(modalState.title === "Transfer Unsuccessful" ||
                  modalState.title === "Transfer Error") && (
                  <div>
                    <Lottie
                      options={failedAnimationData}
                      height={280}
                      width={280}
                    />
                    <h3>Transfer Failed</h3>
                    <p>{modalState.message}</p>
                    <div className="button-wrapper">
                      <Button
                        variant="contained"
                        className="button-faucet"
                        onClick={requestTokens}
                      >
                        Try again
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <Grid container>
          <Grid item xs={12}>
            <div className="jargon__wrapper">
              <div
                className="jargon__description"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <span className="info-description">
                  We are live on &nbsp;<span className="polygon">Polygon</span>
                  &nbsp; testnet 🚀
                </span>
                <h1>
                  Get Digital Asset Insurance <br /> Tailored to Your Portfolio
                </h1>
                <p>
                  The first two-sided digital asset insurance marketplace.
                  <br /> Request and provide insurance on a broad array of
                  digital assets.
                  <br /> Powered by Polygon.
                </p>
                <div className="jargon__button">
                  <Button
                    variant="contained"
                    className="default-button"
                    onClick={() =>
                      window.open("https://app.insured.finance/", "_blank")
                    }
                  >
                    Launch App
                  </Button>
                  <Button
                    onClick={() => setInputModal(true)}
                    variant="contained"
                    className="outlined-button"
                  >
                    Request Tokens
                  </Button>
                </div>
                <span className="jargon__text_info">
                  *Testnet tokens are available.
                </span>
              </div>
              <div
                className="jargon__image"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img src={happyPeople} alt="Happy People" width="700px" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Jargon;
