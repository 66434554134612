import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Featured } from "..";
import custodianImg from "../../images/icons/custodian.png";
import rugPullImg from "../../images/icons/rug-pull.png";
import smartContractImg from "../../images/icons/smart-contract.png";
import stableCoinImg from "../../images/icons/stablecoin.png";
import "./Products.scss";

const Products = () => {
  return (
    <div className="products">
      <Featured />
      <Container fixed>
        <Grid container>
          <Grid item xs={12}>
            <div className="products__description">
              <h1 data-aos="fade-up">Insurance Products</h1>
              <div className="products__wrapper">
                <Box className="products__items" data-aos="fade-down-right">
                  <img
                    src={smartContractImg}
                    alt="Smart Contract Coverage"
                    width={52}
                    height={52}
                  />
                  <h1>Smart Contract Coverage</h1>
                  <p>Protect against bugs and smart contract exploits.</p>
                </Box>
                <Box className="products__items" data-aos="fade-down-left">
                  <img
                    src={custodianImg}
                    alt="Custodian Failure"
                    width={52}
                    height={52}
                  />
                  <h1>Crypto-Custodians Insolvency</h1>
                  <p>
                    If the crypto-custodian is hacked or experiences bankruptcy,
                    users with coverage are compensated.
                  </p>
                </Box>
              </div>
              <div className="products__wrapper">
                <Box className="products__items" data-aos="fade-up-right">
                  <img
                    src={stableCoinImg}
                    alt="Stablecoin Devaluation"
                    width={52}
                    height={52}
                  />
                  <h1>Protect Against Stablecoin Failure</h1>
                  <p>
                    Stablecoins remain exposed to a variety of risks like
                    security lapses and issuer bankruptcy.
                  </p>
                </Box>
                <Box className="products__items" data-aos="fade-up-left">
                  <img
                    src={rugPullImg}
                    alt="Rug Pull Cover"
                    width={52}
                    height={52}
                  />
                  <h1>Rug Pull Cover</h1>
                  <p>
                    Hedge DeFi Positions by covering Rug Pull possibilities. If
                    a rug pull happens, Insured Finance users are covered.
                  </p>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Products;
