import { Button, Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./Articles.scss";

const Articles = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const POSTS_URL =
          "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@insuredfinance";
        const fetchedData = await axios.get(POSTS_URL);
        setPosts(fetchedData.data.items);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  function readingTime(posts) {
    const text = posts.content;
    const wpm = 265;
    const words = text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);

    return time;
  }

  return (
    <div className="articles">
      <Container fixed>
        <Grid container>
          <Grid item xs={12}>
            <div className="articles__description">
              <span data-aos="fade-up">Articles & Insights</span>
              <h1 data-aos="fade-up">
                Subscribe to get full access to the newsletter. <br />
                Never miss an update.
              </h1>
              <Grid container>
                <Grid item xs={12} md={6} data-aos="fade-right">
                  <Box
                    className="articles__items"
                    onClick={() =>
                      window.open(
                        `${posts.length > 0 && posts[0].link}`,
                        "_blank"
                      )
                    }
                  >
                    <div className="articles__items_image">
                      <img src={posts.length > 0 && posts[0].thumbnail} />
                    </div>
                    <div className="articles__items_description">
                      <h1>{posts.length > 0 && posts[0].title}</h1>
                      <div className="attributes">
                        <span>
                          {posts.length > 0 &&
                            moment(posts[0].pubDate).format("ll")}
                        </span>
                        <span>
                          {posts.length > 0 && readingTime(posts[0])} min read
                        </span>
                      </div>
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  data-aos="fade-left"
                  sx={{ pl: { md: 10 } }}
                >
                  {posts
                    .filter((post, index) => index > 0 && index < 4)
                    .map((post, index) => (
                      <Box
                        className="articles__items"
                        onClick={() =>
                          window.open(
                            `${posts.length > 0 && post.link}`,
                            "_blank"
                          )
                        }
                        key={index}
                      >
                        <div className="articles__items_description">
                          <h1>{posts.length > 0 && post.title}</h1>
                          <div className="attributes">
                            <span>
                              {posts.length > 0 &&
                                moment(post.pubDate).format("ll")}
                            </span>
                            <span>
                              {posts.length > 0 && readingTime(post)} min read
                            </span>
                          </div>
                        </div>
                      </Box>
                    ))}
                  <Grid xs={12}>
                    <Button
                      variant="contained"
                      className="custom-button"
                      onClick={() =>
                        window.open("https://app.insured.finance/", "_blank")
                      }
                    >
                      Read more our updates
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Articles;
