import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "gatsby";
import girlCoverage from "../../images/girl-coverage.png";
import manCoverage from "../../images/man-coverage.png";
import "./Coverage.scss";

const Coverage = () => {
  return (
    <div className="coverage">
      <Container fixed>
        <Grid container>
          <Grid item xs={12}>
            <div className="coverage__description">
              <h1 data-aos="zoom-in">
                It’s never been this easy to insure crypto holdings
              </h1>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Box className="coverage__items">
                    <div
                      className="coverage__item_product request-coverage"
                      data-aos="zoom-in-up"
                    >
                      <div className="item-image">
                        <img
                          src={girlCoverage}
                          alt="Girl"
                          width="230px"
                          height="268px"
                        />
                      </div>
                      <div className="item-description">
                        <h1>Request Coverage</h1>
                        <p>
                          Didn’t find the insurance you were looking for? Put a
                          custom request into the marketplace and someone else
                          can fill it.
                        </p>
                        <Link to="https://docs.insured.finance/understanding-insured-finance/things-our-users-can-do">
                          <span>Read More</span>
                        </Link>
                      </div>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="coverage__items last-items">
                    <div
                      className="coverage__item_product provide-coverage"
                      data-aos="zoom-in-down"
                    >
                      <div className="item-description">
                        <h1>Provide Coverage</h1>
                        <p>
                          Fulfill custom requests in the marketplace to earn
                          lucrative premiums.
                        </p>
                        <Link to="https://docs.insured.finance/understanding-insured-finance/things-our-users-can-do">
                          <span>Read More</span>
                        </Link>
                      </div>
                      <div className="item-image">
                        <img
                          src={manCoverage}
                          alt="Man"
                          width="271px"
                          height="268px"
                        />
                      </div>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Coverage;
