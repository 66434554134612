import { Container, Grid } from "@mui/material";
import { Link } from "gatsby";
import Lottie from "react-lottie";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as lottieBackToTop from "../../images/features/lottie-back-to-top.json";
import "./Footer.scss";

const Footer = () => {
  const lottieBackToTopData = {
    loop: true,
    autoplay: true,
    animationData: lottieBackToTop,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const scrollTop = () => {
    console.log("Go to top");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <footer>
        <Container fixed>
          <div className="footer__wrapper">
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <div className="footer__wrapper-left">
                  <div className="logo">
                    <Link to="/">
                      <StaticImage
                        src="../../images/logo.png"
                        alt="Logo"
                        width={160}
                        height={37}
                      />
                    </Link>
                  </div>
                  <p>
                    Insured Finance is a decentralized P2P insurance marketplace
                    <br />
                    with easy claims and instant payouts. Powered by Polygon.
                  </p>
                  <span>Follow Us</span>
                  <div className="social-media">
                    <a href="https://insured.substack.com/" target="_blank">
                      <StaticImage
                        src="../../images/social-media/substack.png"
                        alt="Substack"
                        width={60}
                        height={60}
                      />
                    </a>
                    <a
                      href="https://insuredfinance.medium.com/"
                      target="_blank"
                    >
                      <StaticImage
                        src="../../images/social-media/medium.png"
                        alt="Medium"
                        width={60}
                        height={60}
                      />
                    </a>
                    <a href="https://twitter.com/insuredfin/" target="_blank">
                      <StaticImage
                        src="../../images/social-media/twitter.png"
                        alt="Twitter"
                        width={60}
                        height={60}
                      />
                    </a>
                    <a href="https://t.me/insuredfinance" target="_blank">
                      <StaticImage
                        src="../../images/social-media/telegram.png"
                        alt="Telegram"
                        width={60}
                        height={60}
                      />
                    </a>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className="footer__wrapper-right">
                  <div className="menu-item">
                    <span>Learn</span>
                    <ul>
                      <li>
                        <Link to="/features"> Features </Link>
                      </li>
                      <li>
                        <Link to="https://docs.insured.finance">
                          {" "}
                          Documentation{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="https://insured.substack.com/">
                          Newsletter
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="menu-item">
                    <span>Legal</span>
                    <ul>
                      <li>
                        <Link to="/privacy-policy"> Privacy Policy </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="menu-item">
                    <span>Media</span>
                    <ul>
                      <li>
                        <Link to="/brand-assets"> Brand Assets </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        <div className="back-to-top" onClick={scrollTop}>
          <Lottie
            isClickToPauseDisabled={true}
            options={lottieBackToTopData}
            // height={200}
            width={160}
          />
          <span>Back to Top</span>
        </div>
      </footer>
      <div className="copyright">
        <Container fixed>
          <div className="text">
            <span>
              Copyright © 2020-2021 Insured Finance. All rights reserved.
            </span>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Footer;
