import { Container, Grow, Divider } from "@mui/material";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import TelegramIcon from "../../images/icons/telegram.svg";
import MenuIcon from "@mui/icons-material/Menu";
import "./Header.scss";
import { Helmet } from "react-helmet";
import favicon from "../../images/symbol.png";

export const Hat = ({ title }) => {
  return (
    <Helmet>
      <title>
        {title === "Insured Finance"
          ? "Insured Finance"
          : title + " | Insured Finance"}
      </title>
      <meta name="description" content={title} />
      <link rel="icon" href={favicon} />
    </Helmet>
  );
};

const Header = () => {
  const [clicked, setClicked] = useState(false);

  const handleclick = () => {
    setClicked(!clicked);
    console.log(clicked, "clicked");
  };

  return (
    <React.Fragment>
      <header>
        <Container>
          <div className="wrapper">
            <div className="logo">
              <Link to="/">
                <StaticImage
                  src="../../images/logo.png"
                  alt="Logo"
                  width={160}
                  height={37}
                />
              </Link>
            </div>
            <div className="navigation">
              <ul>
                <li>
                  <Link to="/" activeClassName="active">
                    Home
                  </Link>
                </li>
                <li className="has-sub-nav">
                  <Link
                    to="/features"
                    className={
                      typeof window !== "undefined" &&
                      (window.location.pathname === "/features/" ||
                        window.location.pathname === "/features")
                        ? "active"
                        : ""
                    }
                  >
                    Features
                  </Link>
                </li>

                <li className="has-sub-nav">
                  <Link to="https://docs.insured.finance/">Docs</Link>
                </li>

                <li className="has-sub-nav">
                  <Link to="https://medium.com/@insuredfinance">Blog</Link>
                </li>

                <li>
                  <Link to="https://t.me/insuredfinance" target="_blank">
                    <img src={TelegramIcon} alt="Telegram" />
                    <span className="telegram-text">Telegram</span>
                  </Link>
                </li>
              </ul>
            </div>

            <div
              id="hamburger-icon"
              className={clicked === true ? "open" : ""}
              onClick={handleclick}
            >
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
        </Container>
      </header>

      <div className={clicked === true ? "overlay" : ""}></div>

      <Grow in={clicked}>
        <div className={clicked === true ? "mobile-menu open" : "mobile-menu"}>
          <ul>
            <li>
              <Link to="/" activeClassName="active">
                Home
              </Link>
            </li>
            <Divider light />
            <li className="has-sub-nav">
              <Link
                to="/features"
                className={
                  typeof window !== "undefined" &&
                  (window.location.pathname === "/features/" ||
                    window.location.pathname === "/features")
                    ? "active"
                    : ""
                }
              >
                Features
              </Link>
            </li>
            <Divider light />
            <li className="has-sub-nav">
              <Link to="https://docs.insured.finance/">Docs</Link>
            </li>
            <Divider light />
            <li className="has-sub-nav">
              <Link to="https://medium.com/@insuredfinance">Blog</Link>
            </li>
            <Divider light />
            <li>
              <Link to="https://t.me/insuredfinance" target="_blank">
                <img src={TelegramIcon} alt="Telegram" />
                <span className="telegram-text">Telegram</span>
              </Link>
            </li>
          </ul>
        </div>
      </Grow>
    </React.Fragment>
  );
};

export default Header;
