import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "gatsby";
import React from "react";
import bgcImg from "../../images/community/bgc.png";
import communityPeople from "../../images/community/community-people.png";
import hanwhaImg from "../../images/community/hanwha.png";
import thirdBridgeImg from "../../images/community/third-bridge.png";
import mediumIcon from "../../images/icons/medium.svg";
import telegramIcon from "../../images/icons/telegram.svg";
import twitterIcon from "../../images/icons/twitter.svg";
import "./Community.scss";

const Community = () => {
  return (
    <div className="community">
      <Container fixed>
        <div className="community__wrapper">
          <Grid container>
            <Grid item xs={12}>
              <div className="community__description">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box className="community__items">
                      <h1 data-aos="fade-up">
                        Meet the worldwide
                        <br /> community.
                      </h1>
                      <p data-aos="fade-down">
                        At Insured Finance, our team is your team. Meet the
                        innovative <br />
                        individuals behind Insured Finance.
                      </p>
                      <ul data-aos="fade-right">
                        <li>
                          <Link
                            to="https://t.me/insuredfinance"
                            target="_blank"
                          >
                            <img src={telegramIcon} alt="Telegram" /> Telegram
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://twitter.com/insuredfin/"
                            target="_blank"
                          >
                            <img src={twitterIcon} alt="Twitter" /> Twitter
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://insuredfinance.medium.com/"
                            target="_blank"
                          >
                            <img src={mediumIcon} alt="Medium" /> Medium
                          </Link>
                        </li>
                      </ul>
                      <Grid container spacing={2} className="image-wrapper">
                        <Grid item xs={12} data-aos="fade-right">
                          <span>Executives from: </span>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="image" data-aos="fade-right">
                            <img
                              src={hanwhaImg}
                              alt="Hanwha"
                              width={160}
                              height={80}
                            />
                            <img
                              src={thirdBridgeImg}
                              alt="Third Bridge"
                              width={161}
                              height={25}
                            />
                            <img
                              src={bgcImg}
                              alt="bgc"
                              width={102}
                              height={56}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box></Box>
                    <img
                      className="community__img"
                      src={communityPeople}
                      alt="Community People"
                      width={535}
                      height={281}
                      data-aos="flip-left"
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Community;
